import React from 'react'
import Layout from "../../components/Layout";
import SEO from "../../components/seo";
import FAQ from '../../components/FAQ'
import { Link } from 'gatsby'
import styles from '../../components/layout.module.scss'
import GetStartedOrChat from '../../components/CTA/GetStartedOrChat'

class Page extends React.Component {

    render() {

        return (

            <Layout location={this.props.location}>

                <SEO
                    title="How to Add Sound to Instagram Pictures"
                     description="How to add sound to Instagram pictures to help you boost engagement in your posts and showcase your music or podcast."
                />

                <article>
                    <h1>How to Add Sound to Instagram Pictures?</h1>

                <p>Just about everybody hears about their latest favorite thing from Instagram these days. So if you're a podcast creator or musician and want more people to know about your work—or more importantly, to <em>hear </em>your work—you need to promote it on social media. </p>
                <p>Simply using a photo isn't enough. To gain new fans, you need to give your followers a taste of your latest release.</p>
                <p>How do you do this? By posting a photo with an audio clip in the background to Instagram so that they can hear it for themselves.</p>
                <p>Read on for a complete step-by-step guide to add sound to Instagram pictures. We'll also walk through how to do the process on your Instagram story with EchoWave as well.</p>
                <h2>How to Add Sound to Instagram Pictures</h2>
                <p>Sharing a brief audio clip of your work on Instagram gets your followers interested, and they're more likely to go listen to your music or podcast if they hear a preview that they like instead of just seeing a plain photo.</p>
                <p>The Instagram algorithm also favors audio and video content, so you'll be rewarded with great engagement, and your content will be seen by even more of your current and potential followers.</p>
                <p>You can create an awesome teaser post for free in just five minutes with EchoWave. Let's break down how to add sound to a picture on Instagram. </p>
                <h3>Upload Your Audio File</h3>
                <p>1. First, <a href="https://app.echowave.io/register">create an account with EchoWave</a>. It's completely free to create an account and begin making videos that are up to 15 seconds long.</p>
                <p>If you would like to share photos with audio in the background longer than 15 seconds, you can upgrade to EchoWave Premium for $9.99 a month. </p>
                <p>2. Once you are logged into your account, you will be taken to your personal dashboard. Click Create Video on the left-hand toolbar. </p>
                <p>3. To add your original audio, click Add Sound, and upload your audio clip from your computer. If you are posting about a podcast that has already been published, you can click on Select Podcast and search for it in the database.</p>
                <p>4. Now that you've got your audio clip uploaded or selected, shorten it to the length that you want to post. Use the blue scanner buttons to move the start and end times to the right segment.</p>
                <p>Choose a part of the clip that will be most compelling and inspire followers to search for your song or podcast and listen to the rest. Keep the sample short so that anyone scrolling by on Instagram will stop and give it a listen.</p>
                <p>Once you have narrowed the correct audio clip, click Next.</p>
                <h3>Upload Your Photo or Artwork</h3>
                <p>5. Now move on to Choose Template and click on New Template.</p>
                <p>6. For an Instagram post, select a square template. Click Next.</p>
                <p>7. Now you can add the photo to the audio clip. If you are sharing a song, add your album artwork. If you are sharing a podcast episode, add your main podcast image.</p>
                <p>Click Add Image and then Next once it uploads.</p>
                <p>If you need to create a graphic or artwork, we recommend using <a href="https://designschool.canva.com/tutorials/getting-started/">the free program Canva</a>, an extremely user-friendly graphic design website. You can easily add text to one of your own photos or use their free templates to make an album cover for your song or podcast. They even have documents that are pre-sized for Instagram posts and stories so that you don't have to figure out the correct dimensions on your own.</p>
                <p>8. Once your artwork is added, you can select an animation. This is a waveform that moves with your audio and lets your followers know that this photo has audio in the background, so they're more likely to stop and listen to it.</p>
                <p>You can customize how you want the waveform animation to look as well as the color. If you prefer not to have a waveform on your photo, just leave the dropdown menu blank. The audio will still be in the background of your post.</p>
                <p>9. To preview your post, click the play button under your picture on the right. If you need to change anything, click the category you want to work on and keep editing.</p>
                <p>10. If you are satisfied with the finished product and ready to post it to Instagram, click Render Video.</p>
                <h3>Share to Instagram</h3>
                <p>11. You will receive an email when your post is done rendering and ready to download. Click View Video in the email, which will take you back to your EchoWave dashboard, and then click Download.</p>
                <p>12. Once your video has downloaded to your computer, email it to yourself so that you can access and save it from your phone.</p>
                <p>13. Open the email from your phone and save it to your device.</p>
                <p>14. Now open Instagram and select your video to post. It should be perfectly formatted for a square Instagram post. Craft your caption, add any relevant hashtags, and post it! </p>
                <p>It's also always a good idea to share your Instagram post to your Stories so that more people will see it. Your followers will love hearing a snippet of your podcast episode or song, and Instagram will reward you with high engagement rates!</p>
                <p>You're also more likely to convert followers to active listeners and fans when you give them these little teasers.</p>
                <p>Every time you have new music or a new podcast episode, post one of these teaser videos to let your followers know when your new content is live. </p>
                <p>Your videos will always save on your EchoWave dashboard, so you can log in to view them and download them again at any time. You can also use previous videos as templates for new videos, making the process faster the more you create.</p>
                <h2>Add Audio to Your Instagram Story</h2>
                <p>More of your followers are probably seeing your Instagram Stories over your posts these days, so you'll want to share your work there, too.</p>
                <p>To add an audio clip to an Instagram story, follow the process above until you get to step six. </p>
                <p>Now, select the vertical template shape instead of the square. The vertical size will be formatted to fit perfectly on your stories.</p>
                <p>Move through the rest of the steps as outlined above. We recommend including a waveform in your Stories, too, so that your audience knows they should turn their sound on and listen as they are scrolling through.</p>
                <p>Do you have more questions about how to add sound to a picture on Instagram Stories? Shoot us a message on our live chat so we can help you create the content you need.</p>
                <h2>EchoWave Premium Benefits</h2>
                <p>Upgrade to EchoWave Premium for a special beta price of $9.99 per month to access additional account perks that will take your videos to the next level.</p>
                <p>You can also upgrade to an annual premium account for $99.99 and get two months free.</p>
                <p>With a premium account, you can add custom wave animations, create an unlimited amount of videos, and upload longer audio clips. Try it today!</p>
                <h2>More Tips to Promote Your Work</h2>
                <p>Want to share your content even further to more followers on different social media platforms? Check out this guide to learn how to <a href="https://echowave.io/how-to-post-audio-on-facebook/">post audio on Facebook</a>.</p>
                <p>Podcast promotion takes planning and strategy, and it's a constantly changing online landscape. For tips on planning, your pre-launch, having a successful launch, and what to do post-launch, <a href="https://echowave.io/podcast-promotion/">use this guide</a>. </p>
                <h2>More Free Resources We Love</h2>
                <p>If you want to grow your social media presence so that more people find your work, these free programs will make your life easier and more organized. Use them so you can get more done in less time and expand your reach with high-quality content.</p>
                <ul><li>Edit your photos and visuals <a href="https://iphonephotographyschool.com/vsco/">with the VSCO app</a></li>
                    <li>Track and analyze your reach by searching on SocialMention</li>
                    <li>Create stunning graphics and infographics with Visme, an easy-to-use design tool</li>
                    <li><a href="https://support.google.com/analytics/answer/1008015?hl=en">Use Google Analytics</a> to see how much of your website traffic is coming from your social media accounts</li>
                </ul><h2>Tag Us for More Support</h2>
                <p>Now that you're a pro at adding sound to Instagram pictures to promote your work, we know you're going to find an amazing audience and skyrocket to success.</p>
                <p>We would love to share your content! Tag us <a href="https://www.instagram.com/echowaveio/">@echowaveio on Instagram</a> so that we can repost your amazing clips and get them seen by even more people.</p>
                <p>Do you have other content creator friends who want to learn how to add sound to Instagram pictures? Share this guide with them, so they can join EchoWave, too, and start creating videos today!</p>

                </article>


            </Layout>

        )
    }

}

export default Page
